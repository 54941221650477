import httpInternal from '@/http/httpInternal';
import { stateMerge } from 'vue-object-merge';
import flattenDeep from 'lodash/flattenDeep';
import has from 'lodash/has';
import get from 'lodash/get';
import consoleLog from '@/assets/js/helpers/console-log';
import i18n from '@/setup/i18n-init';

const otpDefault = process.env.NODE_ENV === 'development' ? '1234' : '';

const initialPreReservation = () => ({
  id: null,
  carModelId: null,
  currentQueuePosition: null,
  initialQueuePosition: null,
  paidAmount: null,
  dealerNumber: null,
});

const initialState = () => ({
  loggedIn: false,
  info: {
    phoneCountryCode: 'NO',
    mobileNumber: '',
    firstName: '',
    lastName: '',
    ssn: '',
    address: '',
    zipCode: '',
    city: '',
    countryCode: 'NO',
    email: '',
    userUuid: '',
    partyId: '',
    vinNumber: '',
    carRegistrationNumber: '',
    carMileage: '',
    scopeSalesRetailers: [],
  },
  activePreReservation: initialPreReservation(),
  isCompanyCustomer: false,
  isExistingUser: false,
  newUserCreated: false,
  selectedDealer: '',
  otp: otpDefault,
  otpSent: false,
  otpError: false,
  loginError: false,
  oneTimePassword: '',
  hasChangedInfo: false,
  hasChangedDealer: false,
  hasTradeInVehicle: false,
  previousOrders: null,
  creditCheckPassed: null,
});

const UserStore = {
  state: initialState(),
  getters: {
    user: (state) => state,
    userLoggedIn: (state) => state.loggedIn,
    userInfo: (state) => state.info,
    activePreReservation: (state) => state.activePreReservation,
    userHasPreReservation: (state) => !!state.activePreReservation.id,
    userHasEmail: (state) => !!state.info.email,
    isExistingUser: (state) => state.isExistingUser,
    newUserCreated: (state) => state.newUserCreated,
    showNewUserForm: (state) => !state.isExistingUser && !state.newUserCreated,
    isCompanyCustomer: (state) => state.isCompanyCustomer,
    userOtp: (state) => state.otp,
    userOneTimePassword: (state) => state.oneTimePassword,
    loginError: (state) => state.loginError,
    testOtp: (state) => state.otpSent && state.otp.length === 4,
    otpSent: (state) => state.otpSent,
    otpError: (state) => state.otpError,
    selectedDealer: (state, getters) => (getters.getDealer(state.selectedDealer)
      ? state.selectedDealer
      : ''),
    hasChangedDealer: (state) => state.hasChangedDealer,
    hasTradeInVehicle: (state, getters) => {
      if (!getters.appConfig?.configurator?.features?.allowTradeIn) return false;
      return state.hasTradeInVehicle;
    },
    selectedDealerName: (state, getters) => {
      const { dealerName = '' } = getters.getDealer(state.selectedDealer) || {};
      return dealerName;
    },
    previousOrders: (state) => state.previousOrders || [],
    shouldCheckPreviousOrders: (state, getters) => {
      const { disableOpenOrder = false } = getters.getModelById() || {};
      return getters.isExistingUser && !state.previousOrders && !disableOpenOrder;
    },
    getUserSelectedCountry: (state, getters) => getters.supportedUserCountries
      .find((country) => country.code === state.info.countryCode),
    // @todo Keeping countries here for now, should be set up in backend
    supportedUserCountries: () => [
      {
        code: 'NO',
        international: 'Norway',
        label: i18n.tc('countries.norway'),
      },
    ],
    creditCheckPassed: (state) => state.creditCheckPassed,
  },
  mutations: {
    UPDATE_USER(state, user = {}) {
      stateMerge(state, user);
    },
    RESET_USER(state) {
      const s = initialState();
      Object.keys(initialState())
        .forEach((key) => {
          this._vm.$set(state, key, s[key]);
        });
    },
    UPDATE_USER_INFO_BY_KEY(state, { key = '', value = '' }) {
      this._vm.$set(state.info, key, value);
    },
    UPDATE_USER_PRE_RESERVATION(state, preReservation = initialPreReservation()) {
      Object.keys(preReservation).forEach((key) => {
        if (has(state.activePreReservation, key)) {
          this._vm.$set(state.activePreReservation, key, preReservation[key]);
        }
      });
    },
    UPDATE_USER_OTP(state, value = otpDefault) {
      this._vm.$set(state, 'otp', value);
    },
    UPDATE_OTP_SENT(state, otpSent = false) {
      this._vm.$set(state, 'otpSent', otpSent);
    },
    UPDATE_OTP_ERROR(state, otpError = false) {
      this._vm.$set(state, 'otpError', otpError);
    },
    UPDATE_LOGIN_ERROR(state, loginError = false) {
      this._vm.$set(state, 'loginError', loginError);
    },
    UPDATE_SELECTED_DEALER(state, dealerNumber = '') {
      this._vm.$set(state, 'selectedDealer', dealerNumber);
    },
    UPDATE_IS_COMPANY_CUSTOMER(state, isCompanyCustomer = false) {
      this._vm.$set(state, 'isCompanyCustomer', isCompanyCustomer);
    },
    UPDATE_USER_HAS_CHANGED_INFO(state, hasChangedInfo = false) {
      this._vm.$set(state, 'hasChangedInfo', hasChangedInfo);
    },
    UPDATE_HAS_CHANGED_DEALER(state, hasChangedDealer = false) {
      this._vm.$set(state, 'hasChangedDealer', hasChangedDealer);
    },
    UPDATE_HAS_TRADE_IN_VEHICLE(state, hasTradeInVehicle = false) {
      this._vm.$set(state, 'hasTradeInVehicle', hasTradeInVehicle);
    },
    UPDATE_PREVIOUS_ORDERS(state, previousOrders = null) {
      this._vm.$set(state, 'previousOrders', previousOrders);
    },
    UPDATE_CREDIT_CHECK_PASSED(state, creditCheckPassed = null) {
      this._vm.$set(state, 'creditCheckPassed', creditCheckPassed);
    },
  },
  actions: {
    formatUserInfo({ getters, commit }, { keys = ['firstName', 'lastName', 'address', 'city', 'email'] } = {}) {
      if (!Array.isArray(keys)) return;

      const { userInfo } = getters;

      keys.forEach((key) => {
        const exists = !!get(userInfo, key);
        const value = exists && typeof userInfo[key] === 'string' ? userInfo[key].trim() : '';
        commit('UPDATE_USER_INFO_BY_KEY', { key, value });
      });
    },
    async logout({ getters, dispatch, commit }, alert = true) {
      if ('userAgreements' in getters) commit('RESET_USER_AGREEMENTS');
      if ('userCreation' in getters) commit('RESET_USER_CREATION');
      if ('validations' in getters) commit('RESET_VALIDATIONS');
      if ('companyInfo' in getters) commit('RESET_COMPANY_INFO');
      dispatch('resetOtp');

      if (getters.isDealerLoggedIn) await dispatch('logoutDealer');

      commit('RESET_USER');

      if (alert) {
        dispatch('setAlert', { key: 'logout', message: 'succeeded.logout', type: 'success' });
      }
    },
    async resetOtp({ commit }) {
      commit('UPDATE_USER_OTP');
      commit('UPDATE_OTP_SENT');
      commit('UPDATE_OTP_ERROR');
      commit('UPDATE_LOGIN_ERROR');
    },
    setSelectedDealer({ getters, commit }, dealerNumber = getters.getConfigByKeyString('dealer.defaultDealer')) {
      if (!getters.getDealer(dealerNumber)) return;
      if (
        getters.dealerRestrictions.length > 0
        && !getters.dealerRestrictions.includes(dealerNumber)
      ) return;
      commit('UPDATE_SELECTED_DEALER', dealerNumber);
    },
    unsetSelectedDealer({ commit }) {
      commit('UPDATE_SELECTED_DEALER');
    },
    setHasChangedDealer({ commit }, hasChangedDealer = false) {
      commit('UPDATE_HAS_CHANGED_DEALER', hasChangedDealer);
    },
    setHasTradeInVehicle({ commit }, hasTradeInVehicle = false) {
      commit('UPDATE_HAS_TRADE_IN_VEHICLE', hasTradeInVehicle);
    },
    setIsCompanyCustomer({ commit }, isCompanyCustomer = false) {
      commit('UPDATE_IS_COMPANY_CUSTOMER', isCompanyCustomer);
    },
    async setCreditCheckPassed({ commit }, creditCheckedPassed = false) {
      commit('UPDATE_CREDIT_CHECK_PASSED', creditCheckedPassed);
    },
    async sendCustomerOtp({ getters, commit, dispatch }, {
      phoneNumber = getters.userInfo.mobileNumber,
    } = {}) {
      dispatch('setLoader', true);
      commit('UPDATE_OTP_ERROR', false);

      try {
        await dispatch('ussEnrollNewUser', { phoneNumber });
        dispatch('removeAlert', 'otpError');
        commit('UPDATE_OTP_SENT', true);
        commit('UPDATE_USER_OTP');
        dispatch('setLoader', false);
        return true;
      } catch (error) {
        if (error.response && error.response.status === 429) {
          dispatch('setAlert', { key: 'otpError', message: 'error.otpTooManyRequests' });
          commit('UPDATE_OTP_ERROR', 'error.otpTooManyRequests');
        } else {
          dispatch('setAlert', { key: 'otpError', message: 'error.otpFetch' });
          commit('UPDATE_OTP_ERROR', 'error.otpFetch');
        }
        consoleLog(`Something went wrong when enrolling new user: ${error}`);
        return false;
      }
    },
    async requestOneTimePassword({ getters, dispatch, commit }) {
      dispatch('setLoader', true);

      try {
        const response = await dispatch('ussUserInfo', getters.userOtp);
        commit('UPDATE_USER', { oneTimePassword: response.data.otp });
        return true;
      } catch (error) {
        return false;
      }
    },
    async setUserInputAndValidate({ state, commit, dispatch }, { key = '', value = '' }) {
      if (Object.prototype.hasOwnProperty.call(state.info, key)) {
        commit('UPDATE_USER_INFO_BY_KEY', { key, value });
        const keysToCheck = ['firstName', 'lastName', 'email'];

        if (keysToCheck.includes(key) && !state.hasChangedInfo) commit('UPDATE_USER_HAS_CHANGED_INFO', true);
        if (key === 'zipCode') await dispatch('setCityByZip');

        dispatch('validate', { key, value });
      }
    },
    async fetchAndSetPrimaryPreReservation({ commit }) {
      // @todo This currently has no real use, should it be removed?
      commit('UPDATE_USER_PRE_RESERVATION');

      // try {
      //   const { code: modelCode, year: modelYear } = getters.getModelById() ?? {};
      //
      //   const response = await httpInternal(
      //     {
      //       method: 'get',
      //       url: getters.apiGetPrimaryPreReservationUrl,
      //       params: {
      //         userUuid: getters.userInfo.userUuid,
      //         modelCode,
      //         modelYear,
      //       },
      //     },
      //   );
      //
      //   const { preReservation = {} } = response.data;
      //
      //   commit('UPDATE_USER_PRE_RESERVATION', preReservation);
      //   const { dealerNumber } = getters.activePreReservation;
      //   if (getters.getDealer(dealerNumber) && !getters.hasChangedDealer) {
      //     await dispatch('setSelectedDealer', dealerNumber);
      //   }
      // } catch (error) {
      //   const { status, data = {} } = error.response;
      //   commit('UPDATE_USER_PRE_RESERVATION');
      //
      //   if (!(status === 404 && (data.modelNotFound || data.noPreReservation))) {
      //     dispatch('setAlert', { key: 'getPreReservationError', message: 'error.getPreReservation', log: error.response });
      //     throw error;
      //   }
      // }
    },
    async fetchAndSetPreviousOrders({ getters, commit, dispatch }) {
      try {
        const response = await httpInternal(
          {
            method: 'get',
            url: getters.apiGetEditableOrdersUrl,
            params: {
              userUuid: getters.userInfo.userUuid,
            },
          },
        );
        const { data: orders = [] } = response;
        commit('UPDATE_PREVIOUS_ORDERS', orders);
      } catch (error) {
        commit('UPDATE_PREVIOUS_ORDERS');
        dispatch('setAlert', { key: 'getOrdersError', message: 'error.getOrders', log: error.response });
        throw error;
      }
    },
    async fetchAndSetCustomerInfo({ getters, dispatch, commit }) {
      dispatch('setLoader', true);

      const userResponse = await dispatch('ussUserInfo');

      if (userResponse.status !== 200) return false;

      commit('UPDATE_USER', {
        oneTimePassword: userResponse.data.otp,
      });

      const { userUuid } = userResponse.data;

      if (!userUuid) {
        commit('UPDATE_USER', {
          loggedIn: true,
        });
        return true;
      }

      commit('UPDATE_USER_INFO_BY_KEY', { key: 'userUuid', value: userUuid });

      let customerResponse;
      try {
        customerResponse = await dispatch('ussGetCustomerInfo');
      } catch (e) {
        commit('UPDATE_USER', {
          loggedIn: false,
          oneTimePassword: '',
        });
      }

      const customerData = customerResponse.data.customer || {};

      const phoneCountryCode = customerData.mobileNumberCountryCode
        || getters.userInfo.phoneCountryCode;
      const countryCode = customerData.addressCountry || getters.userInfo.countryCode;
      const mobileNumber = customerData.mobileNumber || getters.userInfo.mobileNumber;
      const firstName = customerData.firstName || '';
      const lastName = customerData.lastName || '';
      const address = customerData.addressLine || '';
      const zipCode = customerData.zipCode || '';
      const city = customerData.city || '';
      const email = customerData.email || '';
      const partyId = customerData.partyId || getters.userInfo.partyId;
      const preferredSalesRetailers = customerData.preferredSalesRetailers || [];

      const { dealers } = getters;

      let scopeSalesRetailers = [];
      preferredSalesRetailers.forEach((retailer) => {
        const retailerBrand = typeof retailer.brand === 'string'
          ? retailer.brand.toUpperCase()
          : null;
        scopeSalesRetailers.push(
          dealers.filter((dealer) => (dealer.dealerNumber === retailer.id)
            && (dealer.saleCertifications.toUpperCase()
              .includes(retailerBrand))),
        );
      });

      scopeSalesRetailers = flattenDeep(scopeSalesRetailers);

      commit('UPDATE_USER', {
        info: {
          phoneCountryCode,
          countryCode,
          mobileNumber,
          firstName,
          lastName,
          address,
          zipCode,
          city,
          email,
          userUuid,
          partyId,
          scopeSalesRetailers,
        },
        oneTimePassword: customerResponse.data.otp,
        isExistingUser: true,
      });

      dispatch('setCityByZip');

      commit('UPDATE_USER', {
        loggedIn: true,
      });

      return true;
    },
  },
};

export default UserStore;
