import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import {
  createConfigStep,
  decodeConfiguratorKey,
  DEFAULT_CONFIGURATOR_KEY_OBJECT,
  encodeConfiguratorKey,
} from '@/assets/js/helpers/configurator-helpers';
import consoleLog from '@/assets/js/helpers/console-log';
import {
  CONFIGURATOR_PREVIEW_KEY,
  CONFIGURATOR_STEPS,
} from '@/constants/constants-configurator';
import { ROUTES } from '@/constants/constants-routes';
import { getClientRouter } from '@/assets/js/helpers/config-helpers';
import { roundNumber, sleep } from '@/assets/js/helpers/general-helpers';

const INITIAL_MODEL_STATE_ITEM = () => ({
  configSteps: [],
  currentConfigStepKey: '',
  previewMode: false,
  showDealerSelect: false,
  selectedMarketModelId: '',
  baseMarketModelId: '',
  linkedFinancingInformationShown: false,
  modelInformationShown: false,
  visitedSteps: [],
});

const ConfiguratorStore = {
  state: {
    activeModelId: null,
    modelStates: {},
  },
  getters: {
    getActiveModelId: (state) => state.activeModelId,
    getModelConfigState: (state) => (
      modelId = state.activeModelId,
    ) => state.modelStates[modelId],

    getModelSelectedMarketModelId: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigState(modelId)?.selectedMarketModelId,

    getModelBaseMarketModelId: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigState(modelId)?.baseMarketModelId,

    getModelConfigSteps: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigState(modelId)?.configSteps ?? [],

    getModelConfigStepsKeys: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigSteps(modelId)?.map?.((step) => step.key),

    getModelConfigStepByKey: (state, getters) => ({
      modelId = state.activeModelId,
      stepKey,
    } = {}) => getters.getModelConfigSteps(modelId)?.find?.((step) => step.key === stepKey),

    getModelStaticConfigSteps: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigSteps(modelId)?.filter?.((step) => !step.isDynamic),

    getModelDynamicConfigSteps: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigSteps(modelId)?.filter?.((step) => step.isDynamic),

    getModelFirstConfigStepKey: (state, getters) => (
      modelId = state.activeModelId,
    ) => head(getters.getModelConfigStepsKeys(modelId)),

    getModelTotalConfigSteps: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigSteps(modelId)?.length,

    getModelCurrentConfigStep: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigSteps(modelId)?.find?.(
      (step) => step.key === getters.getModelCurrentConfigStepKey(modelId),
    ) || {},

    getModelCurrentConfigStepKey: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigState(modelId)?.currentConfigStepKey,

    getModelCurrentConfigStepIndex: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigStepsKeys(modelId)
      ?.indexOf?.(getters.getModelCurrentConfigStepKey(modelId)),

    getModelPrecedingConfigSteps: (state, getters) => ({
      modelId = state.activeModelId,
      stepKey = getters.getModelCurrentConfigStepKey(modelId),
    } = {}) => getters.getModelConfigSteps(modelId)
      ?.filter?.(
        (step, index) => index < getters.getModelConfigStepsKeys(modelId)?.indexOf?.(stepKey),
      ),

    getLineStepMarketModels: (state, getters) => (modelId = state.activeModelId) => {
      const filterFunction = (marketModel) => {
        const { id: marketModelId, hideInConfigurator = false } = marketModel ?? {};
        const isAnUpgrade = getters.isMarketModelAnUpgrade({ marketModelId });

        return !hideInConfigurator && !isAnUpgrade;
      };

      return getters.getMarketModelsByModelId(modelId)
        .filter((marketModel) => filterFunction(marketModel));
    },

    isModelConfigStepEnabled: (state, getters) => ({
      modelId = state.activeModelId,
      stepKey,
    } = {}) => {
      const stepData = getters.getModelConfigStepByKey({ modelId, stepKey });

      if (stepData.isExternal) return false;

      return getters.getModelConfigStepsKeys(modelId).includes(stepKey)
        ? getters.getModelPrecedingConfigSteps({ modelId, stepKey })
          .every((step) => step.completed)
        : false;
    },

    isModelConfigNextEnabled: (state, getters) => (modelId = state.activeModelId) => {
      const currentIndex = getters.getModelCurrentConfigStepIndex(modelId);
      if (currentIndex + 1 === getters.getModelTotalConfigSteps(modelId)) return false;

      const nextStepKey = getters.getModelConfigStepsKeys(modelId)?.[currentIndex + 1];
      return getters.isModelConfigStepEnabled({ modelId, stepKey: nextStepKey });
    },

    isModelConfiguratorPreviewMode: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigState(modelId)?.previewMode,

    showModelConfiguratorDealerSelect: (state, getters) => (
      modelId = state.activeModelId,
    ) => getters.getModelConfigState(modelId)?.showDealerSelect,

    forceModelDealerSelection: (
      state,
      getters,
    ) => getters.getModelById()?.forceDealerSelection ?? false,

    showModelLinkedFinancingInformation: (
      state,
      getters,
    ) => !getters.getModelConfigState()?.linkedFinancingInformationShown ?? false,

    shouldModelInformationAutoShow: (
      state,
      getters,
    ) => {
      const { autoShow, modalContent } = getters.getModelById()?.information ?? {};

      const hasBeenShown = getters.getModelConfigState()?.modelInformationShown ?? true;

      return autoShow && !!modalContent && !hasBeenShown;
    },

    getConfiguratorConsentConfig: (state, getters) => getters.appConfig?.configurator?.consent,

    shouldShowConfiguratorConsent: (state, getters) => {
      const { text, link } = getters.getConfiguratorConsentConfig ?? {};
      return !!text || !!(link?.href && link?.text);
    },

    getConfiguratorKey: (state, getters) => ({
      modelId = state.activeModelId,
    } = {}) => {
      const configuratorKeyObj = DEFAULT_CONFIGURATOR_KEY_OBJECT();

      const marketModelId = getters.getModelSelectedMarketModelId(modelId);
      const baseMarketModelId = getters.getModelBaseMarketModelId(modelId);

      if (marketModelId) {
        configuratorKeyObj.marketModelId = marketModelId;
        configuratorKeyObj.baseMarketModelId = baseMarketModelId;

        configuratorKeyObj.selections = getters.getMarketModelSelectionsData({
          marketModelId,
          filterValid: false,
          skipBundled: false,
        })
          .map((selection) => ({
            id: selection.id,
            sku: selection.sku,
          }));

        Object.entries(getters.getMileageYearPriceStateSettings(marketModelId))
          .forEach(([optionId, value]) => {
            const marketModelOptionId = roundNumber(optionId);

            const { sku } = getters.getMarketModelOptionById({
              marketModelId,
              marketModelOptionId,
            }) ?? {};

            if (sku) {
              configuratorKeyObj.mileageYearPriceSettings.push({
                id: marketModelOptionId,
                sku,
                ...value,
              });
            }
          });
      }

      const { loan = {}, leasing = {} } = getters.getFinancingSettings(marketModelId) ?? {};
      const selectedFinancingOption = getters.getSelectedFinancingOption({
        financingStateKey: modelId,
        allowCashClones: true,
      });

      const selectedCustomerType = getters.getFinancingCustomerType(modelId);

      configuratorKeyObj.financing = {
        selectedFinancingOption,
        selectedCustomerType,
        settings: {
          loan,
          leasing,
        },
      };

      return encodeConfiguratorKey(configuratorKeyObj);
    },

    getConfiguratorCacheKeySuffix: (state, getters) => {
      const customerTypeKey = `:${getters.getFinancingCustomerType()}`;
      const dealerKey = `:${getters.selectedDealer}`;
      const paymentTypeKey = `:${getters.getFinancingOptionData()?.paymentType}`;

      return `${customerTypeKey}${dealerKey}${paymentTypeKey}`;
    },

    isStockCarConfigurator: (state, getters) => ({
      modelId = state.activeModelId,
    } = {}) => !!getters.getModelById(modelId)?.isStockCarModel,
  },
  mutations: {
    UPDATE_MODEL_CONFIGURATOR_STATE(state, {
      modelId,
      modelState = INITIAL_MODEL_STATE_ITEM(),
    } = {}) {
      if (!modelId) return;
      this._vm.$set(state.modelStates, modelId, modelState);
    },

    UPDATE_ACTIVE_MODEL_ID(state, modelId = null) {
      state.activeModelId = modelId;
    },

    UPDATE_MODEL_SELECTED_MARKET_MODEL_ID(state, {
      modelId = state.activeModelId,
      marketModelId = '',
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'selectedMarketModelId', marketModelId);
    },

    UPDATE_MODEL_BASE_MARKET_MODEL_ID(state, {
      modelId = state.activeModelId,
      baseMarketModelId = '',
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'baseMarketModelId', baseMarketModelId);
    },

    UPDATE_MODEL_CONFIG_STEPS(state, {
      modelId = state.activeModelId,
      steps = [],
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'configSteps', steps);
    },

    UPDATE_MODEL_CONFIG_STEP_COMPLETE(state, {
      modelId = state.activeModelId,
      stepKey,
      completeState = true,
    } = {}) {
      const stepIndex = state.modelStates[modelId]?.configSteps
        ?.findIndex?.((step) => step.key === stepKey);
      if (stepIndex === -1) return;

      this._vm.$set(state.modelStates[modelId].configSteps[stepIndex], 'completed', completeState);
    },

    UPDATE_MODEL_CURRENT_CONFIG_STEP(state, {
      modelId = state.activeModelId,
      stepKey = '',
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'currentConfigStepKey', stepKey);
    },

    UPDATE_MODEL_CONFIGURATOR_PREVIEW_MODE(state, {
      modelId = state.activeModelId,
      previewMode = false,
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'previewMode', previewMode);
    },

    UPDATE_MODEL_CONFIG_SHOW_DEALER_SELECT(state, {
      modelId = state.activeModelId,
      showDealerSelect = false,
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'showDealerSelect', showDealerSelect);
    },

    UPDATE_MODEL_LINKED_FINANCING_INFORMATION_SHOWN(state, {
      modelId = state.activeModelId,
      linkedFinancingInformationShown = false,
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'linkedFinancingInformationShown', linkedFinancingInformationShown);
    },

    UPDATE_MODEL_INFORMATION_SHOWN(state, {
      modelId = state.activeModelId,
      modelInformationShown = false,
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'modelInformationShown', modelInformationShown);
    },

    ADD_MODEL_VISITED_STEP(state, {
      modelId = state.activeModelId,
      stepKey = '',
    } = {}) {
      state.modelStates[modelId]?.visitedSteps?.push?.(stepKey);
    },

    RESET_MODEL_VISITED_STEP(state, {
      modelId = state.activeModelId,
    } = {}) {
      this._vm.$set(state.modelStates[modelId], 'visitedSteps', []);
    },

  },
  actions: {
    async initModelConfiguratorState({ getters, commit, dispatch }, { modelId, skipMarketModels = false }) {
      if (getters.getModelConfigState(modelId)) return;

      commit('UPDATE_MODEL_CONFIGURATOR_STATE', { modelId });
      await dispatch('setInitialModelConfigSteps', modelId);
      await dispatch('initFinancingState', modelId);
      await dispatch('initOpenedOrderState', modelId);

      const model = getters.getModelById(modelId);

      try {
        const financingOptions = model?.financing?.options;

        await dispatch('setAvailableFinancingOptions', { financingStateKey: modelId, financingOptions });
        await dispatch('setFinancingCustomerType', { financingStateKey: modelId, updateConfiguratorKey: false });
      } catch (error) {
        commit('UPDATE_ACTIVE_MODEL_ID');
        throw error;
      }

      if (getters.isStockCarConfigurator({ modelId })) return;

      if (skipMarketModels) return;

      try {
        await dispatch('fetchAndSetMarketModels', { modelId });
      } catch (error) {
        commit('UPDATE_ACTIVE_MODEL_ID');
        throw error;
      }
    },

    async setConfigActiveModelId({ getters, commit, dispatch }, { modelId, skipMarketModels = false }) {
      if (!modelId) {
        dispatch('setAlert', { key: 'invalidModelError', message: 'error.modelNotFound' });
        throw new Error('Missing model ID');
      }

      if (modelId === getters.getActiveModelId) return;

      if (!getters.getModelById(modelId)) {
        dispatch('setAlert', { key: 'invalidModelError', message: 'error.modelNotFound' });
        throw new Error('Invalid model');
      }

      await dispatch('initModelConfiguratorState', { modelId, skipMarketModels });

      commit('UPDATE_ACTIVE_MODEL_ID', modelId);
    },

    async setModelSelectedMarketModel({ getters, commit, dispatch }, {
      marketModelId = '',
      baseMarketModelId = '',
      updateConfiguratorKey = true,
    } = {}) {
      if (!getters.getMarketModelById(marketModelId)) {
        dispatch('setAlert', { key: 'errorMmNotFound', message: 'error.marketModelNotFound' });
        throw new Error('Invalid Market Model');
      }

      const modelId = getters.getMarketModelModelId(marketModelId);
      await dispatch('resetModelConfiguratorStepsAfterCurrent', { modelId, marketModelId });

      await dispatch('setModelBaseMarketModel', { marketModelId, baseMarketModelId, updateConfiguratorKey: false });
      commit('UPDATE_MODEL_SELECTED_MARKET_MODEL_ID', { modelId, marketModelId });

      await dispatch('checkAndSelectOptionsRequiredForFinancingOption', {
        marketModelId,
        updateConfiguratorKey: false,
        updateAutoSelectedOptionsData: false,
      });

      if (updateConfiguratorKey) await dispatch('setConfiguratorKeyParam', { modelId });
    },

    async setModelBaseMarketModel({ getters, commit, dispatch }, {
      marketModelId = '',
      baseMarketModelId = '',
      updateConfiguratorKey = true,
    } = {}) {
      if (!marketModelId) return;

      const modelId = getters.getMarketModelModelId(marketModelId);

      if (!getters.isMarketModelAnUpgrade({ marketModelId })) {
        commit('UPDATE_MODEL_BASE_MARKET_MODEL_ID', { baseMarketModelId: marketModelId });
        if (updateConfiguratorKey) await dispatch('setConfiguratorKeyParam', { modelId });
        return;
      }

      const baseMmId = baseMarketModelId || getters.getModelSelectedMarketModelId(modelId);

      if (getters.isMarketModelValidUpgradeForBase({ marketModelId, baseMarketModelId: baseMmId })) {
        commit('UPDATE_MODEL_BASE_MARKET_MODEL_ID', { modelId, baseMarketModelId: baseMmId });
      }

      if (updateConfiguratorKey) await dispatch('setConfiguratorKeyParam', { modelId });
    },

    async setInitialModelConfigSteps({ state, getters, dispatch }, modelId = state.activeModelId) {
      try {
        const steps = await dispatch('createModelConfigSteps', modelId);
        await dispatch('checkAndSetModelConfigSteps', { modelId, steps });

        await dispatch('setModelCurrentConfigStep', { modelId, stepKey: getters.getModelFirstConfigStepKey(modelId) });
      } catch (error) {
        dispatch('setAlert', {
          key: 'errorConfigurator',
          message: 'error.initConfigurator',
          log: error,
        });
        throw error;
      }
    },

    async checkAndSetModelConfigSteps(
      {
        state, getters, commit, dispatch,
      },
      {
        modelId = state.activeModelId,
        marketModelId = getters.getModelSelectedMarketModelId(),
        steps,
      } = {},
    ) {
      let stepsArray = Array.isArray(steps)
        ? [...steps]
        : [...getters.getModelConfigSteps(modelId)];

      if (
        !getters.getModelPreReservationRequired(modelId)
        || getters.isModelConfiguratorPreviewMode(modelId)
      ) {
        stepsArray = await dispatch('removeModelConfigStep', { modelId, stepKey: CONFIGURATOR_STEPS.login, steps: stepsArray });
      }

      stepsArray = await dispatch('checkMarketModelUpgradeStep', {
        marketModelId,
        steps: stepsArray,
      });

      commit('UPDATE_MODEL_CONFIG_STEPS', { modelId, steps: stepsArray });
    },

    async createModelConfigSteps({ state, getters }, modelId = state.activeModelId) {
      const stepsConfig = cloneDeep(getters.getModelById(modelId)?.configurator?.steps);

      if (!stepsConfig) throw new Error('No steps config');

      stepsConfig.push(...getters.getFinancingOptionExtraSteps({ financingStateKey: modelId }));

      const steps = [];

      stepsConfig.forEach((stepSettings, index) => {
        if (!steps.some((step) => step.key === stepSettings.key)) {
          const stepObj = createConfigStep({
            stepKey: stepSettings.key,
            properties: {
              sortOrder: index,
              ...stepSettings,
            },
          });

          if (stepObj && !stepObj?.isDynamic) steps.push(stepObj);
        }
      });

      const configurationStep = createConfigStep({ stepKey: CONFIGURATOR_STEPS.configuration });
      if (configurationStep) steps.push(configurationStep);

      return sortBy(steps, ['sortOrder']);
    },

    async removeModelConfigStep({
      state, getters, commit, dispatch,
    }, {
      modelId = state.activeModelId,
      stepKey = '',
      steps,
    } = {}) {
      const shouldUpdateState = steps === undefined;
      if (getters.getModelCurrentConfigStepKey(modelId) === stepKey) await dispatch('setModelPrevConfigStep', modelId);

      const stepsArray = Array.isArray(steps)
        ? [...steps]
        : [...getters.getModelConfigSteps(modelId)];

      const index = stepsArray.findIndex((step) => step.key === stepKey);
      if (index === -1) return stepsArray;

      stepsArray.splice(index, 1);

      if (shouldUpdateState) commit('UPDATE_MODEL_CONFIG_STEPS', { modelId, steps: stepsArray });
      return stepsArray;
    },

    async addModelConfigStep({
      state, getters, commit, dispatch,
    }, {
      modelId = state.activeModelId,
      stepKey = '',
      steps,
    } = {}) {
      const shouldUpdateState = steps === undefined;
      const stepsArray = Array.isArray(steps)
        ? [...steps]
        : [...getters.getModelConfigSteps(modelId)];

      const matchStep = (step) => step.key === stepKey;
      if (stepsArray.find((step) => matchStep(step))) return stepsArray;

      const defaultSteps = await dispatch('createModelConfigSteps', modelId);

      let stepToAdd;

      if (stepKey === CONFIGURATOR_STEPS.upgrade) {
        stepToAdd = createConfigStep({ stepKey: CONFIGURATOR_STEPS.upgrade });
      } else {
        stepToAdd = defaultSteps.find((step) => matchStep(step));
      }

      if (!stepToAdd) return stepsArray;

      const index = stepsArray.findIndex((step) => step.sortOrder > stepToAdd.sortOrder);
      const stepAfter = stepsArray[index] || {};

      if (
        getters.isModelConfigStepEnabled({ modelId, stepKey: stepAfter.key })
      ) stepToAdd.completed = true;

      stepsArray.splice(index, 0, stepToAdd);

      if (shouldUpdateState) commit('UPDATE_MODEL_CONFIG_STEPS', { modelId, steps: stepsArray });
      return stepsArray;
    },

    async resetModelConfigurator({ state, getters, dispatch }, {
      modelId = state.activeModelId,
      closeOpenedOrder = true,
    } = {}) {
      await dispatch('setInitialModelConfigSteps', modelId);
      await dispatch('resetModelConfiguratorExceptSteps', { modelId, closeOpenedOrder });
      await dispatch('clearSuccessData');
      await dispatch('resetModelVisitedSteps', { modelId });

      const { marketModels: marketModelIds } = getters.getModelById(modelId);

      const resetMarketModelPromises = marketModelIds
        .map(async (marketModel) => {
          await dispatch('initMarketModelConfigurationState', {
            marketModelId: marketModel.id,
            force: true,
          });
          await dispatch('preselectMarketModelStandardOptions', { marketModelId: marketModel.id });
        });

      await Promise.all(resetMarketModelPromises);

      if (getters.isStockCarConfigurator(modelId)) {
        const [stockCarRegistrationNumber] = marketModelIds;
        await dispatch('initStockCarsConfiguratorData', { stockCarRegistrationNumber });
      }

      await dispatch('setConfiguratorKeyParam', { modelId });
    },

    async resetModelConfiguratorStepsAfterCurrent({ getters, dispatch }, {
      modelId,
      marketModelId,
      closeOpenedOrder = false,
    } = {}) {
      const newStepState = await dispatch('createModelConfigSteps', modelId);

      newStepState.forEach((step, index) => {
        const stateStep = getters.getModelConfigStepByKey({ modelId, stepKey: step.key });
        if (!stateStep) return;

        if (stateStep.sortOrder <= getters.getModelCurrentConfigStep(modelId)?.sortOrder) {
          newStepState[index] = getters.getModelConfigStepByKey({ modelId, stepKey: step.key });
        }
      });

      await dispatch('checkAndSetModelConfigSteps', { modelId, marketModelId, steps: newStepState });
      await dispatch('resetModelConfiguratorExceptSteps', { modelId, closeOpenedOrder });
    },

    async resetModelConfiguratorExceptSteps({ commit }, {
      modelId,
      closeOpenedOrder = true,
    } = {}) {
      if (closeOpenedOrder) commit('UPDATE_OPENED_ORDER_STATE', { openedOrderStateId: modelId });
      commit('UPDATE_MODEL_SELECTED_MARKET_MODEL_ID', { modelId });
    },

    async resetModelConfiguratorForCheckingSelections({
      state, getters, dispatch,
    }, modelId = state.activeModelId) {
      const dynamicStepsKeys = getters.getModelDynamicStepsData({ modelId })
        ?.map?.((step) => step.key);

      const marketModelId = getters.getModelSelectedMarketModelId(modelId);
      const incompleteStepsPromises = dynamicStepsKeys.map(async (stepKey) => {
        await dispatch('setMarketModelDynamicStepCompletedState', { marketModelId, stepKey, forceIncomplete: false });
      });

      await Promise.all(incompleteStepsPromises);
      await dispatch('setModelCurrentConfigStep', { modelId, stepKey: CONFIGURATOR_STEPS.configuration });
    },

    validateModelConfigStep({ state, getters, dispatch }, {
      modelId = state.activeModelId,
      stepKey = getters.getModelCurrentConfigStepKey(modelId),
    } = {}) {
      const step = getters.getModelConfigStepByKey({ modelId, stepKey });
      if (!step?.validate) return;

      const completeState = getters.getMarketModelIncompleteCategoriesInStep({
        stepKey,
      }).length <= 0;
      dispatch('setModelConfigStepComplete', { modelId, stepKey, completeState });
    },

    setModelConfigStepComplete({ state, getters, commit }, {
      modelId = state.activeModelId,
      stepKey = getters.getModelCurrentConfigStepKey(modelId),
      completeState = true,
    } = {}) {
      commit('UPDATE_MODEL_CONFIG_STEP_COMPLETE', { modelId, stepKey, completeState });
    },

    async completeModelPrecedingConfigSteps({ state, getters, dispatch }, {
      modelId = state.activeModelId,
      stepKey = getters.getModelCurrentConfigStepKey(modelId),
    } = {}) {
      const precedingStepsKeys = getters.getModelPrecedingConfigSteps({ modelId, stepKey })
        .map((step) => step.key);

      const completePrecedingPromises = precedingStepsKeys.map(async (precedingStepKey) => {
        await dispatch('setModelConfigStepComplete', { modelId, stepKey: precedingStepKey });
      });

      await Promise.all(completePrecedingPromises);
    },

    async setModelCurrentConfigStep({
      state, getters, commit, dispatch,
    }, {
      modelId = state.activeModelId,
      stepKey = '',
      checkInventory = true,
    } = {}) {
      if (
        !stepKey
        || stepKey === getters.getModelCurrentConfigStepKey(modelId)
        || !getters.getModelConfigStepsKeys(modelId)?.includes?.(stepKey)
      ) return;

      const newStepKey = getters.isModelConfigStepEnabled({ modelId, stepKey })
        ? stepKey
        : getters.getModelPrecedingConfigSteps({ modelId, stepKey })
          ?.find?.((step) => !step.completed).key;

      if (newStepKey === getters.getModelCurrentConfigStepKey(modelId)) return;

      if (checkInventory) dispatch('checkModelConfigInventory', { modelId, stepKey });

      // Dirty fix to not track confirmation page, should do better
      if (!getters.successOrderId) {
        dispatch('trackCustomerPreReservationConversion', {
          action: `Step - ${newStepKey.charAt(0).toUpperCase() + newStepKey.slice(1)}`,
        });
      }
      commit('UPDATE_MODEL_CURRENT_CONFIG_STEP', { modelId, stepKey: newStepKey });

      await dispatch('addModelVisitedStep', { modelId, stepKey: newStepKey });

      this._vm.$tracking.datadog.configuratorStepChanged();
      this._vm.$tracking.adobe.configuratorStepChanged();
    },

    async setModelPrevConfigStep({ state, getters, dispatch }, {
      modelId = state.activeModelId,
      checkInventory = true,
    } = {}) {
      const stepKey = getters.getModelConfigStepsKeys(
        modelId,
      )?.[getters.getModelCurrentConfigStepIndex(modelId) - 1];
      if (!stepKey) return;
      await dispatch('setModelCurrentConfigStep', { modelId, stepKey, checkInventory });
    },

    async setModelNextConfigStep({ state, getters, dispatch }, {
      modelId = state.activeModelId,
      checkInventory = true,
    } = {}) {
      if (!getters.isModelConfigNextEnabled(modelId)) return;
      const stepKey = getters.getModelConfigStepsKeys(
        modelId,
      )?.[getters.getModelCurrentConfigStepIndex(modelId) + 1];

      await dispatch('setModelCurrentConfigStep', {
        modelId,
        stepKey,
        checkInventory,
      });
    },

    async addModelVisitedStep({ state, getters, commit }, {
      modelId = state.activeModelId,
      stepKey = '',
    } = {}) {
      const shouldAdd = (key) => {
        const { configSteps = {}, visitedSteps = [] } = state.modelStates[modelId] ?? {};
        const validStepIndex = configSteps?.findIndex?.((step) => step.key === key);
        if (validStepIndex === -1) return false;
        return !visitedSteps.includes(key);
      };

      const stepsToCheck = getters.getModelPrecedingConfigSteps({ modelId, stepKey })
        .map((step) => step.key);
      stepsToCheck.push(stepKey);

      stepsToCheck.forEach((key) => {
        if (!shouldAdd(key)) return;
        commit('ADD_MODEL_VISITED_STEP', { modelId, stepKey: key });
        this._vm.$tracking.datadog.configuratorStepVisited(modelId, key);
      });
    },

    resetModelVisitedSteps({ state, commit }, {
      modelId = state.activeModelId,
    } = {}) {
      commit('RESET_MODEL_VISITED_STEP', { modelId });
    },

    async checkModelConfigInventory({ state, getters, dispatch }, {
      modelId = state.activeModelId,
      stepKey = getters.getModelCurrentConfigStepKey(modelId),
    } = {}) {
      const excludedSteps = getters.getModelStaticConfigSteps(modelId)
        ?.map?.((step) => step.key) || [];
      if (!getters.getModelUsesInventory(modelId) || excludedSteps.includes(stepKey)) return;

      try {
        await dispatch('fetchAndSetMarketModelOptionsInventory');
        await dispatch('checkMarketModelOutOfStockSelections');
      } catch (error) {
        consoleLog(error);
      }
    },

    async checkModelAllowedToConfigure() {
      // @todo This currently has no real use, should we remove it?
      return {
        allowed: true,
      };

      // const { code: modelCode, year: modelYear } = getters.getModelById(modelId) || {};
      //
      // let response;
      // try {
      //   // @TODO This should be a get in the future. Post today due to caching in cloudfront
      //   response = await httpInternal(
      //     {
      //       method: 'post',
      //       url: getters.apiAllowedToConfigureUrl,
      //       data: {
      //         userUuid: getters.userInfo.userUuid,
      //         modelCode,
      //         modelYear,
      //       },
      //     },
      //   );
      //   const { data = {} } = response;
      //   return {
      //     allowed: data.allowed,
      //   };
      // } catch (error) {
      //   const { status, data = {} } = error.response;
      //
      //   if (status === 403) {
      //     return {
      //       allowed: data.allowed,
      //       reason: data.reason,
      //       userGroupStartTime: data.userGroupStartTime,
      //     };
      //   }
      //
      //   dispatch('setAlert', {
      //     key: 'allowedToConfigureError',
      //     message: 'error.allowedToConfigureFailed',
      //     log: error.response,
      //   });
      //   throw error;
      // }
    },

    async setModelConfiguratorPreviewMode({
      state, getters, commit, dispatch,
    }, {
      modelId = state.activeModelId,
      previewMode = false,
      reset = false,
    } = {}) {
      // @todo Change preview mode identifier from Query param to be part of URL configuration key?
      const { allowPreview } = getters.getModelPreviewData(modelId);

      const newPreviewModeState = allowPreview ? previewMode : false;
      const currentPreviewModeState = getters.isModelConfiguratorPreviewMode(modelId);
      const shouldReset = currentPreviewModeState !== newPreviewModeState && reset;

      commit('UPDATE_MODEL_CONFIGURATOR_PREVIEW_MODE', { modelId, previewMode: newPreviewModeState });

      const router = await getClientRouter();

      const { currentRoute } = router || {};
      const query = cloneDeep(currentRoute.query);

      newPreviewModeState
        ? query[CONFIGURATOR_PREVIEW_KEY] = null
        : delete query[CONFIGURATOR_PREVIEW_KEY];

      try {
        await router.replace({
          ...currentRoute,
          query,
        });
      } catch (e) {
        // Do nothing..
      }

      if (shouldReset) {
        dispatch('setLoader', true);
        await dispatch('pageTransitionOut');

        await sleep(100);
        await dispatch('resetModelConfigurator', { modelId });
      }
    },

    async setModelConfiguratorShowDealerSelect({ state, commit }, {
      modelId = state.activeModelId,
      showDealerSelect = false,
    } = {}) {
      commit('UPDATE_MODEL_CONFIG_SHOW_DEALER_SELECT', { modelId, showDealerSelect });
    },

    async setModelLinkedFinancingInformationShown({ state, commit }, {
      modelId = state.activeModelId,
      linkedFinancingInformationShown = false,
    } = {}) {
      commit('UPDATE_MODEL_LINKED_FINANCING_INFORMATION_SHOWN', { modelId, linkedFinancingInformationShown });
    },

    async setModelInformationShown({ state, commit }, {
      modelId = state.activeModelId,
      modelInformationShown = false,
    } = {}) {
      commit('UPDATE_MODEL_INFORMATION_SHOWN', { modelId, modelInformationShown });
    },

    async setConfiguratorKeyParam({ state, getters, dispatch }, {
      modelId = state.activeModelId,
    } = {}) {
      const router = await getClientRouter();
      const { currentRoute } = router;
      const { name, params, query } = currentRoute;

      const acceptedRoutes = [ROUTES.configurator, ROUTES.stockCarsConfigurator];
      if (!acceptedRoutes.includes(name)) return;

      const configuratorKey = getters.getConfiguratorKey({ modelId });

      if (configuratorKey === params.configuratorKey) return;

      await dispatch('preventModalCloseOnRouteChange');
      try {
        params.configuratorKey = configuratorKey;

        await router.replace({ name, params, query });
      } catch (error) {
        consoleLog(error);
      }
      await dispatch('allowModalCloseOnRouteChange');
    },

    async initConfigurationFromKeyParam({
      state, getters, commit, dispatch,
    }, {
      modelId = state.activeModelId,
    } = {}) {
      try {
        const router = await getClientRouter();
        const { configuratorKey, stepKey } = router?.currentRoute?.params ?? {};

        if (!configuratorKey) {
          await dispatch('setConfiguratorKeyParam', { modelId });
          return;
        }

        const configuratorKeyObj = decodeConfiguratorKey(configuratorKey);

        const {
          marketModelId,
          baseMarketModelId,
          selections,
          financing,
          mileageYearPriceSettings,
        } = configuratorKeyObj;

        if (!marketModelId || !Array.isArray(selections)) {
          await dispatch('resetModelConfigurator', { modelId });
          return;
        }

        const getOptionByIdOrSku = (id, sku) => {
          let validOption = getters.getMarketModelOptionById({
            marketModelId,
            marketModelOptionId: id,
          });

          if (!validOption) {
            validOption = getters.getMarketModelOptions({ marketModelId })
              .find((option) => option.sku === sku);
          }

          return validOption;
        };

        const rawSelectionIds = [];

        const isValid = selections.every((selection) => {
          const validOption = getOptionByIdOrSku(selection.id, selection.sku);

          if (validOption) rawSelectionIds.push(validOption.id);
          return !!validOption;
        });

        const selectionIds = [...new Set(rawSelectionIds)];

        if (!isValid) {
          await Promise.reject(Error(`Invalid configuration for Model ${modelId}: ${configuratorKey}`));
        }

        await dispatch('setModelSelectedMarketModel', { marketModelId, baseMarketModelId, updateConfiguratorKey: false });

        commit('UPDATE_MARKET_MODEL_SELECTIONS', { marketModelId, selectionIds });

        await dispatch('validateMarketModelConfiguration', { marketModelId });
        await dispatch('setModelCurrentConfigStep', { modelId, stepKey });

        try {
          await dispatch('fetchAndSetSelectionBasedData', { marketModelId });
        } catch (error) {
          consoleLog(error);
        }

        try {
          const mypPromises = [];
          mileageYearPriceSettings.forEach((mypObj) => {
            const existingOption = getOptionByIdOrSku(mypObj.id, mypObj.sku);

            if (existingOption) {
              mypPromises.push(
                dispatch('setOptionMileageYearPriceSettings', {
                  marketModelId,
                  marketModelOptionId: existingOption.id,
                  annualMileage: mypObj.annualMileage,
                  period: mypObj.period,
                  updateConfiguratorKey: false,
                }),
              );
            }
          });
          await Promise.all(mypPromises);
        } catch (error) {
          consoleLog(error);
        }

        try {
          await dispatch('setFinancingCustomerType', {
            financingStateKey: modelId,
            customerType: financing.selectedCustomerType,
            updateConfiguratorKey: false,
          });
        } catch (error) {
          consoleLog(error);
        }

        try {
          await dispatch('setSelectedFinancingOption', {
            financingStateKey: modelId,
            financingOptionType: financing.selectedFinancingOption,
            allowCashClones: true,
            updateConfiguratorKey: false,
          });

          const financingSettingsPromises = [];
          Object.keys(financing.settings).forEach((optionKey) => {
            Object.entries(financing.settings[optionKey]).forEach(([settingKey, value]) => {
              financingSettingsPromises.push(
                dispatch('setFinancingSetting', {
                  financingStateKey: marketModelId,
                  financingOptionType: optionKey,
                  settingKey,
                  value,
                  updateConfiguratorKey: false,
                }),
              );
            });
          });
          await Promise.all(financingSettingsPromises);
        } catch (error) {
          consoleLog(error);
        }

        await dispatch('setConfiguratorKeyParam', { modelId });
      } catch (error) {
        await dispatch('resetModelConfigurator', { modelId });
        dispatch('setAlert', { key: 'invalidConfigurationError', message: 'error.invalidConfiguration', log: error });
        throw error;
      }
    },

    async validateConfiguratorCustomer({ getters, dispatch }, {
      setError = false,
      formatData = false,
      validateConsent = true,
      validateTradeIn = true,
    } = {}) {
      // @todo NO NEW USER PASSWORD
      const userValid = await dispatch('validateUser', {
        setError, formatData, ignoreNewUser: true,
      });
      // const userValid = await dispatch('validateUser', {
      //   setError, formatData,
      // });

      const termsValid = getters.shouldShowConfiguratorConsent && validateConsent
        ? await dispatch('validate', { key: 'termsConsent', value: getters.userAgreements.termsConsent, setError })
        : true;

      const companyValid = getters.isFinancingCustomerTypeCompany()
        ? await dispatch('validateCompanyInfo', { setError, basicOnly: true })
        : true;

      const tradeInValidation = getters.hasTradeInVehicle && validateTradeIn
        ? await dispatch('validate', { key: 'carRegistrationNumber', value: getters.userInfo.carRegistrationNumber, setError })
        : true;

      return userValid && companyValid && termsValid && tradeInValidation;
    },

    async checkMarketModelUpgradeStep({ getters, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      steps,
    } = {}) {
      const modelId = getters.getMarketModelModelId(marketModelId);
      const isAnUpgrade = getters.isMarketModelAnUpgrade({ marketModelId });
      const hasUpgrades = getters.getMarketModelUpgrades({ marketModelId })?.length > 0;

      const action = !isAnUpgrade && !hasUpgrades
        ? 'removeModelConfigStep'
        : 'addModelConfigStep';

      const stepsArray = Array.isArray(steps)
        ? [...steps]
        : [...getters.getModelConfigSteps(modelId)];

      return dispatch(action, {
        modelId,
        stepKey: CONFIGURATOR_STEPS.upgrade,
        steps: stepsArray,
      });
    },

  },
};

export default ConfiguratorStore;
